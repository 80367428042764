<template>
  <div class="container">
    <BasicTitle :title="$route.name" />
    <BasicSubtitle subtitle="Los campos señalados con (*) son obligatorios." />
    <div class="container-form">
      <BasicLabel label="Administradora *" />
      <BorderSelect
        v-model="user.data.companyId"
        :disabled="user.disabled"
        label="Administradora *"
        :options="store.state.general.companies"
      />
      <FormError
        :show="user.rules.companyId"
        message="Seleccione una administradora"
      />
      <BasicLabel label="Nombre *" />
      <BorderInput
        v-model="user.data.name"
        label="Nombre *"
        @keyup.enter="onSave"
      />
      <FormError :show="user.rules.name" message="Ingrese un nombre" />
      <BasicLabel label="CI *" />
      <BorderInput
        v-model="user.data.identity"
        label="CI *"
        @keyup.enter="onSave"
      />
      <FormError
        :show="user.rules.identity"
        message="Ingrese un número de documento válido sin espacios, puntos y guiones"
      />
      <BasicLabel label="Email *" />
      <BorderInput
        v-model="user.data.email"
        label="Email *"
        @keyup.enter="onSave"
      />
      <FormError :show="user.rules.email" message="Ingrese un email válido" />
      <BasicLabel label="Teléfono *" />
      <div class="pd-flex">
        <BorderInputPrefix label="+598" />
        <BorderInputPhone
          v-model="user.data.phone"
          label="Teléfono *"
          @keyup.enter="onSave"
        />
      </div>
      <FormError
        :show="user.rules.phone"
        message="Ingrese un teléfono válido"
      />
    </div>
    <PrimaryButton label="Guardar" :click="onSave" />
  </div>
</template>

<script>
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import store from "../../store";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import { useRouter, useRoute } from "vue-router";
import { openMode, validation, actions } from "../../constants";

export default {
  components: {
    BasicTitle,
    BasicLabel,
    BasicSubtitle,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const user = reactive({
      disabled: false,
      data: {
        id: "",
        companyId: "",
        name: "",
        identity: "",
        email: "",
        phone: "",
      },
      rules: {
        companyId: false,
        name: false,
        identity: false,
        email: false,
        phone: false,
      },
    });

    const validate = () => {
      let valid = true;

      if (!user.data.companyId) {
        user.rules.companyId = true;
        valid = false;
      }
      if (!user.data.name) {
        user.rules.name = true;
        valid = false;
      }
      if (!user.data.identity || !validation.validateCI(user.data.identity)) {
        user.rules.identity = true;
        valid = false;
      }
      if (!user.data.email || !validation.emailRegex.test(user.data.email)) {
        user.rules.email = true;
        valid = false;
      }
      if (!user.data.phone || !validation.phoneRegex.test(user.data.phone)) {
        user.rules.phone = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        id: user.data.id,
        companyId: user.data.companyId,
        name: user.data.name,
        identity: user.data.identity,
        email: user.data.email,
        phone: `+598 ${user.data.phone}`,
      };

      let response = null;
      if (store.state.openMode === openMode.MODIFY) {
        response = await store.dispatch(actions.userActions.update, request);
      } else {
        response = await store.dispatch(actions.userActions.create, request);
      }
      store.commit("setLoading", false);
      if (response.ok) {
        store.commit("setSelectedUsers", []);
        router.push("/usuarios/listado");
      } else {
        store.state.toast.add({
          severity: "error",
          summary: "",
          detail: response.message,
          life: 5000,
        });
      }
    };

    onBeforeMount(async () => {
      if (!store.state.openMode) router.push("/usuarios/listado");
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      if (store.state.openMode === openMode.MODIFY) {
        user.disabled = true;
        const selected = store.state.user.selectedUsers[0];
        user.data.id = selected.id;
        user.data.companyId = selected.companyId;
        user.data.name = selected.name;
        user.data.identity = selected.identity;
        user.data.email = selected.email;
        user.data.phone = selected.phone ? selected.phone.split(" ")[1] : "";
      }
      store.commit("setLoading", true);
      await store.dispatch(
        actions.generalActions.companies,
        store.state.auth.user.company
      );
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (user.data.companyId) user.rules.companyId = false;
      if (user.data.name) user.rules.name = false;
      if (user.data.identity && validation.validateCI(user.data.identity))
        user.rules.identity = false;
      if (user.data.email && validation.emailRegex.test(user.data.email))
        user.rules.email = false;
      if (user.data.phone && validation.phoneRegex.test(user.data.phone))
        user.rules.phone = false;
    });

    return { store, user, onSave };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
